@import "../../constants/mixins";
@import "../../constants/colors";
@import "../../../node_modules/bootstrap/scss/functions";
@import "../../../node_modules/bootstrap/scss/variables";
@import "../../../node_modules/bootstrap/scss/mixins/breakpoints";

$text-color: rgba(white, 0.8);
$link-color: rgba(white, 0.5);

.footer {
  flex: 1;
  width: 100%;
  background-color: rgba($primary, 1);
  color: $text-color;
  padding-top: 2rem;
  
  a {
    color:$link-color;
  }

  .row {
    margin: 0;
  }
  
  @include media-breakpoint-down(md) {
    font-size: 80%;
  }
}

.copyright {
  background-color: rgba($gray-500, 1);
  padding: 0.5rem 0;
  text-align: center;
  color: black;
}
