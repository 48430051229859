@import "../../constants/colors";

.address-totals {
  tr {
    color: $primary;

    td {
      vertical-align: middle;
    }

    &.archived {
      color: $gray-500;
    }

    td:first-child {
      max-width: 80%;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    td:last-child {
      width: 100px;
      vertical-align: middle;
    }
  }

}