

.table-with-head {
  .pagination {
    display: flex;
    align-items: center;
  }
  
  select {
    margin: 0 6px;
    width: fit-content;
  }

  ul {
    margin: auto 2rem 0;

    .cursor-pointer {
      cursor: pointer;
    }
  }
}
