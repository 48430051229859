@import "../../constants/colors";

.address-highlight-percent{
  position: relative;
  
  .highlight {
    opacity: 0.1;
    background-color: $primary;
    position: absolute;
    height: 100%;
    top: 0;
    z-index: -1;
    border-radius: 1rem;    
    &.archived {
      background-color: $gray-600;
    }

  }
}