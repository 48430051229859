.address-table {
  tr {
    td:first-child {
      padding: 0;
      vertical-align: middle;
      input {
        display: inline;
      }
    }
  }
}