@import "../../constants/colors";


$rightBorder : 1px solid rgba($primary, 0.5);
$cornerRadius: 1rem;

.address-percent-bar {
  display: flex;
  color: $primary;
  cursor: default;

  .address-container {
    position: relative;
    border-right: $rightBorder;

    .percentages {
      display: flex;
      justify-content: space-between;
      padding: 0.5rem;
      color: rgba($primary, 0.5);
    }


    &:first-child {
      & > :first-child {
        border-top-left-radius: $cornerRadius;
        border-bottom-left-radius: $cornerRadius;
        overflow: hidden;
      }
      .percentages .left {
        opacity: 0;
      }
    }

    &:nth-child(odd) > :first-child{
      background-color: rgba($primary, 0.05);
    }

    &:nth-child(even) > :first-child {
      background-color: rgba($secondary, 0.05);
    }
  
    &:last-child {
      min-width: 0.5rem;
      border-right: none;
      
      & > :first-child {
        border-top-right-radius: $cornerRadius;
        border-bottom-right-radius: $cornerRadius;
        overflow: hidden;
      }


      .percentages .right {
        opacity: 0;
      }
    }
  }
}