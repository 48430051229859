@import "../constants/colors";
@import "../constants/mixins";

.loader {
  @include flex();
  background: rgba($body-bg, 0.7);
  height: 100%;
  width: 100%;
  z-index: 100;
  top: 0;
  left: 0;
  cursor: default;
  animation: fadein 240ms ease-in-out 1;

  div {
    border: 4px;
    border-style: solid;
    border-radius: 100%;
    animation: round 500ms linear infinite;
  }
}

@keyframes round {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
