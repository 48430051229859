@import "../../constants/colors";
@import "../../constants/mixins";
@import "../../../node_modules/bootstrap/scss/functions";
@import "../../../node_modules/bootstrap/scss/variables";
@import "../../../node_modules/bootstrap/scss/mixins/breakpoints";


$pendingColor: $gray-400;
$activeColor: $primary;
$completedColor: rgba($primary, 0.3);
$labelSpaceAbove: 1.5rem;

.verification-tracker {
  position: relative;
  cursor: default;

  .label {
    padding: 2rem;
  }

  .blob {
    position: relative;

    .label {
      margin-top: $labelSpaceAbove;
      width: 100%;
      font-size: 80%;
      text-align: center;
      display: none;
      padding: 0;
    }
  }

  .blobs {
    @include flex(space-between);
    margin: auto;
    max-width: 15rem;
  }


  .stage {
    @include flex();
    // border: 2px solid $pendingColor;
    color: $pendingColor;
    color: white;
    background-color: $pendingColor;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    transition: background-color 200ms ease-in, height 200ms ease-in, width 200ms ease-in;

    span {
      display: none;
    }

    &.active {
      cursor: pointer;
      border-color: $activeColor;
      background-color: $activeColor;
      width: 4rem;
      height: 4rem;
      font-size: 180%;
      @include boxShadow();
    }

    &.completed {
      border-color: $completedColor;
      background-color: $completedColor;
    }
  }

  .line {
    flex: 1;
    border: 1px solid $pendingColor;
    transition: border-color 200ms ease-in;

    &.completed {
      border-color: $completedColor;
    }
  }

  @include media-breakpoint-up(lg) {

    .label {
      display: none;
    }

    .blobs {
      padding-bottom: $labelSpaceAbove * 2;
      max-width: 40rem;
    }

    .blob .label {
      display: block;
      position: absolute;
    }

    .stage {
      width: 4.5rem;
      height: 4.5rem;
      padding: 1rem;

      &.active {
        width: 7rem;
        height: 7rem;
        font-size: 150%;

      }

      ion-icon {
        font-size: 300%;
      }
    }

    .stage span {
      display: block;
      font-size: 80%;
      overflow: hidden;
      text-align: center;

    }
  }
}