@import "../../constants/mixins";

.address-hover {

  display: block;
  cursor: default;
  overflow: hidden;
  & > div {
    position: relative
    
  }

  & > div{
    height: 1rem;
    line-height: 1rem;

  }

  span {
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
    overflow: hidden;
    width: 100%;

  }

  .label, .address {
    transition: transform 200ms ease-in, opacity 300ms ease-in, visibility 300ms ease-in;
  }

  .label {
    transform: translateX(0);
    opacity: 1;
  }


  .address {
    transform: translateX(-130%);
    opacity: 0;
    position: absolute
    
  }

  &:hover {
    .label {
      opacity: 0;
      visibility: hidden;
      transform: translateX(-130%);

    }
    .address {
      transform: translateX(0);
      opacity: 1;
    }
  }
}