@import '../constants/mixins';
@import '../constants/colors';

.gpib-card {
  @include boxShadowShallow();
  padding: 1.5rem;
  margin-bottom: 2rem;
  border-radius: 5px;
  position: relative;
}
